import React from 'react';
import routes from "../routes";
import * as api from "../utils/api";
import {logEvent, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import AppContext from "../contexts/AppContext";
import {generatePath} from "react-router";
import {creativeIsFailed} from "../utils/creative";

const FETCH_INTERVAL = 1000;

const PHOTO_STATUS_FAILED = -1;
const PHOTO_STATUS_PROCESSED = 1;

export default class CreatePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      salt: 0,
      photo: null,
    };

    this.createPhoto = this.createPhoto.bind(this);
    this.fetchPhoto = this.fetchPhoto.bind(this);
    this.handlePhotoStatus = this.handlePhotoStatus.bind(this);

    this.photoCreatedAt = 0;
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.createPhoto(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  createPhoto(file) {
    api.createPhoto(file)
      .then((res) => {
        logEvent(userEvents.PHOTO_UPLOADED);

        this.photoCreatedAt = Date.now();
        this.handlePhotoStatus(res);
      })
      .catch((err) => {
        logEvent(userEvents.PHOTO_UPLOAD_FAILED);
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  fetchPhoto(photoId) {
    api.fetchPhotoById(photoId)
      .then(this.handlePhotoStatus)
      .catch((err) => {
        console.error(err);
        this.props.history.replace(routes.ERROR);
      });
  }

  handlePhotoStatus(res) {
    if (res.photo.status === PHOTO_STATUS_PROCESSED) {
      logEvent(userEvents.PROCESSING_PROCESSED, {
        elapsed_time_ms: Date.now() - this.photoCreatedAt,
      });

      this.props.history.replace({
        pathname: generatePath(routes.RESULT, {hash: res.photo.hash}),
        state: {photo: res.photo}
      });
      return;
    }

    if (res.photo.status === PHOTO_STATUS_FAILED) {
      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - this.photoCreatedAt,
      });

      this.props.history.replace({
        pathname: routes.ERROR,
        state: {message: res.photo.error || undefined}
      });
      return;
    }

    this.setState({
      photo: res.photo,
      salt: res.salt,
    });

    this.fetchTimer = setTimeout(() => this.fetchPhoto(res.photo.id), FETCH_INTERVAL);
  }

  render() {
    return <Loading image={this.state.photo && this.state.photo.file.url} />;
  }
}

CreatePage.contextType = AppContext;