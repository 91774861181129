module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",

    "landing__subtitle": "Still, there is no time for sadness! We’re here with your expectations vs reality collage.",
    "landing__text_1": "The results vary from creepy to somehow weirdly attractive.",
    "landing__text_2": "Sometimes dope, but rarely dull.",
    "landing__select_photo_button": "Choose photo",

    "processing_text_1": "Have you also gained weight during quarantine?",
    "processing_text_2": "Nothing more to watch on Netflix, huh?",
    "processing_text_3": "Lying on a sofa, dreaming of Bahamas.",
    "processing_text_4": "Who is coughing over there? Go isolate yourself!",
    "processing_text_5": "No more online conferences, please.",
    "processing_text_6": "We got that quarantine, quarantine sadness.",

    "collage__title": "Looks like a new meme 😄",
    "collage__text": " Share it with friends! #my2020recap",
    "collage__tab_image": "Image",
    "collage__tab_video": "Video",
    "collage__tab_0": "Simpsons",
    "collage__tab_1": "Disney",
    "collage__tab_2": "Archer",
    "collage__tab_3": "Vector",
    "collage__tab_4": "Collage",
    "collage__select_photo": "Try with your photo",
    "collage__download": "Download",
    "collage__refresh": "Refresh",

    "download_modal__title": "Don’t stop here!",
    "download_modal__message": "How does it feel to be a toon star? Share your moment of fame on Instagram by posting the result you like most!",
    "download_modal__message_wait": "Creating the result in HD…",
    "download_modal__message_ready": "HD version is ready, download started…",
    "download_modal__dismiss_button": "Got it!",
    "download_modal__download_button": "Download again",

    "error__error": "Error",

    "internal_error": "An error occurred...",
  },
};

